.aboutus-main-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 581px;
  background-color: #7f703d;
}
.abotus-heading {
  font-family: "Poppins";
  font-weight: 300;
  line-height: 1.5em;
  text-align: center;
  font-size: 36px;
  letter-spacing: 0.25em;
  background: transparent;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 4rem;
}
.aboutus-paragraph {
  width: 550px;
  font-family: "Poppins";
  font-weight: 300;
  letter-spacing: 0.1em;
  line-height: 1.8em;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 4rem;
}
.aboutus-icons-block {
  display: flex;
  flex-direction: row;
  width: 550px;
  justify-content: space-around;
  align-items: center;
}
.icons-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-icon {
  width: 70px;
  height: 70px;
  color: #ffffff;
  margin-bottom: 10px;
}
.icon-name {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.5em;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .aboutus-main-block {
    width: 100%;
    height: auto;
  }
  .abotus-heading {
    margin-top: 20px;
    font-size: 28px;
    margin-bottom: 30px;
  }
  .aboutus-paragraph {
    font-size: 14px;
    width: 90%;
    height: auto;
    margin: 10px;
  }
  .aboutus-icons-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .icons-div {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .about-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .icon-name {
    font-size: 12px;
    margin-top: 10px;
  }
}
