.shop-main-block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.shop-head {
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #7f703d;
  text-transform: uppercase;
  line-height: 1px;
  margin-top: 5rem;
}
.card-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.shop-card-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.5rem;
}
.card-hover-imges {
  width: 310px;
  height: 310px;
  position: absolute;
  right: 0%;
  object-fit: cover;
  object-position: top;
}
.card-hover-btn {
  right: 0%;
  bottom: 0%;
  outline: none;
  box-shadow: none;
  border: none;
  text-align: center;
  position: absolute;
  font-family: "Poppins";
  font-size: 16px;
  width: 310px;
  height: 44px;
  background-color: #fff8fabd;
  color: #2c2c2c;
  border: none;
  border-radius: 0px;
}
.card-imgs-blk {
  position: relative;
}
.card-shop-imges {
  object-fit: cover;
  width: 310px;
  height: 310px;
  object-position: top;
}
.card-texts {
  text-align: center;
  margin-top: 10px;
}
.card-title-1 {
  font-size: 16px;
  font-weight: 300;
  font-family: "Poppins";
  color: #756b4b;
}
.modal-main-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1400px;
  height: 500px;
  align-items: center;
}

.modal-imges {
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: top;
}
.product-heading {
  font-size: 18px;
  font-family: "Poppins";
  margin: 10px;
}
.product-info {
  font-size: 14px;
  font-family: "Poppins";
  margin: 10px;
}
.modal-close-btn {
  background-color: #7f703d;
  width: 150px;
  border: none;
  border-radius: 0px;
}
@media screen and (max-width: 768px) {
  .shop-main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .shop-head {
    font-size: 20px;
  }
  .card-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .shop-card-col {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-hover-imges {
    width: 100%;
    height: auto;
  }
  .card-hover-btn {
    font-size: 14px;
    width: 100%;
    height: auto;
    outline: none;
    position: absolute;
    bottom: -2.5%;
  }
  .card-hover-btn:focus {
    border: none;
    box-shadow: none;
    font-size: 14px;
    width: 100%;
    height: auto;
  }
  .card-imgs-blk {
    width: 100%;
    height: auto;
  }
  .card-shop-imges {
    width: 100%;
    height: auto;
  }
  .card-texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  .card-title-1 {
    font-size: 14px;
    width: 100%;
    height: auto;
  }
  .modal-main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  .modal-col {
    margin: 0px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal-imges {
    width: 100%;
    height: auto;
  }
  .product-heading {
    font-size: 24px;
  }
  .product-info {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .modal-close-btn {
    width: 100%;
    height: auto;
  }
}
