.home-page-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-img-blk {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.home-img1 {
  width: 100%;
  height: 645px;
  object-fit: cover;
}
.outside-box-blk {
  width: 100%;
  height: 100%;

  position: relative;
}
.inside-img-blk {
  width: 450px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid;
  box-sizing: border-box;
  position: absolute;
}
.heading-one {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.5em;

  font-size: 36px;
  letter-spacing: 0.2em;
  background: transparent;
  text-transform: uppercase;
  color: #7f703d;
}
.heading-two {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 300;
  letter-spacing: 0.6em;
  color: #7f703d;
  text-transform: uppercase;
  line-height: 1px;
}
.hr-line {
  width: 400px;
  margin-left: 25px;
}

.home-icon-block {
  min-width: 0;
  width: 64px;
  height: 64px;
  border: unset;
  background: unset;
  padding: unset;
  cursor: pointer;
  font: unset;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px 0 hsl(0deg 1% 71% / 50%);
  position: fixed;
  color: rgb(255, 255, 255);
  background-color: rgb(127, 112, 61);
  border-radius: 0px;
  right: 5%;
}

.home-icon {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 600px) {
  .home-page-main {
    width: 100%;
    height: auto;
    margin: 20px auto 20px auto;
  }
  .home-img-blk {
    width: 100%;
    height: auto;
  }
  .home-img1 {
    width: 100%;
    height: 500px;
  }
  .outside-box-blk {
    width: 100%;
    height: auto;
  }
  .inside-img-blk {
    width: 100%;
    height: auto;
    align-items: center;
    border: none;
  }
  .heading-one {
    font-size: 32px;
    padding: 40px;

    padding-bottom: 0px;
    letter-spacing: 0px;
  }
  .heading-two {
    font-size: 18px;
    padding: 20px;

    letter-spacing: 0px;
  }
  .hr-line {
    width: 80%;
    height: auto;
    align-items: center;
    margin: 10px;
  }
  .home-img-2,
  .home-img2 {
    width: 100%;
    height: auto;
  }
  .parallax-effect {
    width: 100%;
    height: auto;
  }
  .home-icon-block {
    width: 40px;
    height: 40px;
  }
  .home-icon {
    width: 40px;
    height: 40px;
  }
  .parallax-effect {
    width: 100%;
    height: auto;
    min-height: 300px;
  }
}
