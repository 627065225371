.contact-main-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f5;
  width: 100%;
  height: 80vh;
}
.contact-heading {
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #7f703d;
  text-transform: uppercase;
  line-height: 1px;
  margin-top: 5rem;
}
.contact-paragraph {
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
  color: #756b4b;
  text-align: center;
  margin-top: 4rem;
  line-height: 1em;
  letter-spacing: 0.15em;
}
.connect-us-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 90px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #756b4b;
  border-radius: 5px;
}

.contact-btn-text {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  margin: 0px;
  color: #f6f5f5;
  line-height: 1em;
  letter-spacing: 0.15em;
}
.whatsapp-icon {
  font-size: 36px;
  color: #f6f5f5;
  margin-right: 10px;
}
.whtslink {
  text-decoration: none;
  color: #f6f5f5;
}
/* .contact-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.form-labels {
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
  color: #756b4b;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1em;
  letter-spacing: 0.15em;
}
.form-inputs {
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
  color: #756b4b;
  width: 240px;
  height: 30px;
  border: 1px solid;
  border-color: #756b4b;
  padding: 0px;

  background-color: transparent;
  line-height: 1em;
  letter-spacing: 0.15em;
}
.right-side-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
}
.form-textarea {
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
  color: #756b4b;
  height: 210px;
  width: 240px;
  background-color: transparent;
}
.contact-submit-btn {
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins";
  color: #ffffff;
  width: 133px;
  height: 30px;
  background-color: #756b4b;

  border: none;
  margin: 10px auto auto 110px;
}
.contact-submit-btn:hover {
  background-color: #2a2626;
} */
@media screen and (max-width: 600px) {
  .contact-main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .contact-heading {
    font-size: 18px;
    margin: 40px;
  }
  .contact-paragraph {
    font-size: 12px;
    margin: 20px;
  }
  .connect-us-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 90px;
  }

  .contact-btn-text {
    font-size: 16px;
  }
  /* .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .form-labels {
    font-size: 14px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .form-inputs {
    font-size: 14px;
    padding: 0px;
    width: 90%;
    height: 44px;
  }
  .right-side-form,
  .left-side-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px;
  }
  .form-textarea {
    font-size: 14px;
    padding: 0px;
    width: 90%;
    height: 150px;
  }
  .contact-submit-btn {
    width: 90%;
    height: 30px;
    font-size: 14px;
    margin: 20px 0px 0px 0px;
  } */
}
