.navbar-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
}
.nav-links {
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
  list-style: none;
  width: 100%;
  padding: 0px;
  margin-right: 4rem;
}

.scroll-link {
  text-decoration: none;
  display: inline;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 40px;
  font-family: "Poppins";
  font-size: 18;
  font-weight: 300;
  cursor: pointer;
}
.navber-home {
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}
.hamburger-menu {
  display: none;
}
.parallax-effect {
  width: 100%;
  background-image: url("../public/assets/shop-img26.jpeg");
  min-height: 700px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.clogo {
  height: 80%;
  margin-left: 4rem;
}
@media screen and (max-width: 600px) {
  .navbar-main {
    flex: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
  }
  .clogo {
    margin-left: 0px;
  }
  .navber-home {
    align-items: center;
    width: 100%;
    height: auto;
  }
  .parallax-effect {
    width: 100%;
    height: auto;
  }
  .nav-links {
    display: none;
  }
  .scroll-link {
    width: 100%;
    text-align: center;
    position: relative;
    font-size: 24px;

    height: auto;
    padding: 32px;
    transition: all 0.5 ease;
  }

  .hamburger-menu {
    display: block;
    position: absolute;
    font-size: 30px;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
    width: 50px;
    height: 50px;
  }
  .nav-links-mobile {
    display: flex;
    flex-wrap: wrap;
    flex: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    position: relative;
    margin: 0px;
    padding: 0px;
    height: auto;
    background-color: #fff;
    left: 0;
    top: 240px;
    z-index: 10;
    transition: all 0.5s ease-out;
    width: 100%;
  }
}
